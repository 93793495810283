<template>
  <!-- <div class="backgroundimage"> -->
  <div>
    <!-- <row>
      <img alt="Vue logo" src="../assets/logo.png">
    </row> 
    <HelloWorld msg="Welcome to Your Home1111"/>  -->
    <el-row>
      <img src="../assets/images/day.jpeg" alt="Pulpit rock" width="600" height="730">
      <!-- <img src="../assets/images/57.gif" alt="Pulpit rock" width="600" height="730"> -->
    </el-row>

  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  // components: {
  //   HelloWorld
  // },
  data() {
    return {};
  },
};
</script>

<style scoped>
.backgroundimage {
  background-image: url("../assets/images/day.jpeg");
  background-size: cover;
  width: 600px;
  height: 730px;
  background-position: right;
}
.image {
  width: 600px;
  height: 730px;
}
</style>
